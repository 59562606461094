// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-request-access-tsx": () => import("./../src/pages/request-access.tsx" /* webpackChunkName: "component---src-pages-request-access-tsx" */),
  "component---src-pages-startups-tsx": () => import("./../src/pages/startups.tsx" /* webpackChunkName: "component---src-pages-startups-tsx" */),
  "component---src-pages-sunset-tsx": () => import("./../src/pages/sunset.tsx" /* webpackChunkName: "component---src-pages-sunset-tsx" */),
  "component---src-pages-use-cases-align-team-tsx": () => import("./../src/pages/use-cases/align-team.tsx" /* webpackChunkName: "component---src-pages-use-cases-align-team-tsx" */),
  "component---src-pages-use-cases-consolidate-feedback-tsx": () => import("./../src/pages/use-cases/consolidate-feedback.tsx" /* webpackChunkName: "component---src-pages-use-cases-consolidate-feedback-tsx" */),
  "component---src-pages-use-cases-engage-customers-tsx": () => import("./../src/pages/use-cases/engage-customers.tsx" /* webpackChunkName: "component---src-pages-use-cases-engage-customers-tsx" */),
  "component---src-pages-use-cases-index-tsx": () => import("./../src/pages/use-cases/index.tsx" /* webpackChunkName: "component---src-pages-use-cases-index-tsx" */),
  "component---src-pages-use-cases-qualify-feedback-tsx": () => import("./../src/pages/use-cases/qualify-feedback.tsx" /* webpackChunkName: "component---src-pages-use-cases-qualify-feedback-tsx" */),
  "component---src-pages-userstand-tsx": () => import("./../src/pages/userstand.tsx" /* webpackChunkName: "component---src-pages-userstand-tsx" */),
  "component---src-pages-works-with-any-app-tsx": () => import("./../src/pages/works-with/any-app.tsx" /* webpackChunkName: "component---src-pages-works-with-any-app-tsx" */),
  "component---src-pages-works-with-front-tsx": () => import("./../src/pages/works-with/front.tsx" /* webpackChunkName: "component---src-pages-works-with-front-tsx" */),
  "component---src-pages-works-with-index-tsx": () => import("./../src/pages/works-with/index.tsx" /* webpackChunkName: "component---src-pages-works-with-index-tsx" */),
  "component---src-pages-works-with-intercom-tsx": () => import("./../src/pages/works-with/intercom.tsx" /* webpackChunkName: "component---src-pages-works-with-intercom-tsx" */),
  "component---src-pages-works-with-other-tsx": () => import("./../src/pages/works-with/other.tsx" /* webpackChunkName: "component---src-pages-works-with-other-tsx" */),
  "component---src-pages-works-with-slack-communities-tsx": () => import("./../src/pages/works-with/slack-communities.tsx" /* webpackChunkName: "component---src-pages-works-with-slack-communities-tsx" */),
  "component---src-pages-works-with-slack-shared-channels-tsx": () => import("./../src/pages/works-with/slack-shared-channels.tsx" /* webpackChunkName: "component---src-pages-works-with-slack-shared-channels-tsx" */),
  "component---src-templates-interview-tsx": () => import("./../src/templates/interview.tsx" /* webpackChunkName: "component---src-templates-interview-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */)
}

